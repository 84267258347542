import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function UniqueStructure() {
  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-lg-8">
          <div className="row mb-2">
            <div className="col">
              {/* Content-1 */}
              <Link
                to={"/vicariate-details?from=parish"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/Mukkali.jpeg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                        }}
                      >
                        Our Parishes
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
            <div className="col">
              {/* Content-2 */}
              <Link
                to={"/diocese-priest?from=priest"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/priest.jpg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                        }}
                      >
                        Our Priests
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              {/* Content-1 */}
              <a
                href={
                  "https://youtube.com/@sansebacommunications?si=0WCixls9l_-nP5dE"
                }
                rel="noreferrer"
                target="_blank"
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/live.png"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                        }}
                      >
                        Live Online
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </a>
            </div>

            <div className="col">
              {/* Content-2 */}
              <Link
                to={"/mass-timings"}
                className="responsive-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-container">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src="/images/all-img/masstime.jpg"
                      className="custom-card-img"
                    />
                    <div className="item-detail">
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontFamily: "'Poppins', sans-serif !important",
                        }}
                      >
                        Mass Timings
                      </h3>
                    </div>
                    <div className="image_overlay"></div>
                  </Card>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          {/* Content-5 */}
          <div className="image-container">
            <Card>
              <Card.Img
                variant="top"
                src="/images/all-img/latestbishop.jpg"
                style={{ height: "33rem" }}
              />
              <div className="image_overlay"></div>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default UniqueStructure;
