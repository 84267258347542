import React, { useState, useEffect, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";

const Quotation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Memoize the verses array
  const verses = useMemo(() => [1], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % verses.length);
    }, 24 * 60 * 60 * 1000); // 24 hours interval
    return () => clearInterval(interval);
  }, [verses.length]); // Only depends on the length of verses

  return (
    <div className="container-lg">
      <div className="row">
        <div className="col-sm-12">
          <div id="myCarousel" className="carousel slide">
            <div className="carousel-inner">
              {verses?.map((verse, index) => (
                <div
                  className={`carousel-item ${index === currentIndex ? "active" : ""
                    }`}
                  key={index}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="section-heading">
                        <h2 className="entry-title text-black">
                          Daily Bible Quotation
                        </h2>
                      </div>
                      <div className="testimonial">
                        <iframe
                          src="https://www.topverses.com/widget2.php?find1=&fg=FFFFFF&&font=16pt Garamond&raw=color:white;font-family:sans-serif;rate=H&"
                          style={{
                            width: "100%",
                            height: "160px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            margin: "10px 0",
                          }}
                          title="biblequote"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;