import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../components/API/Api';
import { CircularProgress } from '@mui/material';

function Refund() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/get/Pages`);
                setData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <CircularProgress sx={{ display: 'block', margin: 'auto', marginTop: '5em' }} />;
    }

    if (!data || data.length === 0) {
        return <div className='text-center mt-5'><b>No Refund & Cancellation Available</b></div>;
    }

    const filteredData = data.filter(item => item.id === 52);

    if (filteredData.length === 0) {
        return <div className='text-center mt-5'><b>No matching data found</b></div >;
    }

    return (
        <>
            <div className="container subpage">
                <div className="row">
                    <div className="col-lg-12">
                        {filteredData.map(item => (
                            <div key={item.id}>
                                <h2 className="heading">{item.title}</h2>
                                <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Refund;