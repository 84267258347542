import React from 'react'

const Education = () => {
  return (
    <>
      
    </>
  )
}

export default Education
