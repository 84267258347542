import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="home-page-welcome">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 order-2 order-lg-1">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">
                    Welcome to Roman Catholic Diocese of Sultanpet
                  </h2>
                </header>
                <div className="entry-content mt-2">
                  Holy Father Francis erected the diocese of Sultanpet, in
                  Palakkad district, Kerala, as a new Latin Catholic Diocese on
                  28th December 2013. The Syro-Malabar diocese was created some
                  40 years ago in this district of Palakkad as: The Diocese of
                  Palakkad. Therefore in order to avoid confusion this new Latin
                  rite diocese in the same district of Palakkad was named as:
                  the Diocese of Sultanpet. Sultanpet is a small area inside the
                  Palakkad town, where the new Cathedral (St. Sebastian’s, near
                  the stadium bus stand) is situated in the town of Palakkad.
                </div>

                <center>
                  <Link to="/history">
                    <button className="brown-button">Read More</button>
                  </Link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
