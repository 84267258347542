import React from 'react';
import { Link } from "react-router-dom";
import './styles.css';

const LatestNews = ({ projectdata }) => {
  const latestEvents = projectdata?.sort((a, b) => b.id - a.id)?.slice(0, 4) || [];
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-heading">
            <h2 className="entry-title">Latest News</h2>
          </div>
        </div>
      </div>
      {latestEvents?.length === 0 && (
        <div className="row">
          <div className="col-12">
            <p>No data available</p>
          </div>
        </div>
      )}
      {latestEvents?.length > 0 && (
        <div className="row">
          {latestEvents?.map((event, index) => (
            <div key={index} className="col-lg-3">
              <div className="event-card data-news">
                <Link to="/latestevents">
                  <img
                    className="event-card-img-top"
                    src={event?.media_url || 'images/all-img/noimage.png'}
                    alt={event?.title}
                  />
                  <div className="event-card-body">
                    <h5 className="event-card-title data-news ">{event?.title}</h5>
                    <p className="event-card-text">
                      <small className="text-muted">
                        <i className="fas fa-calendar-alt" />
                        {event?.eventdate}
                        <i className="fas fa-list" />
                        {event?.category_name}
                      </small>
                    </p>
                    <Link to="/latestevents">
                      <button className="brown-button btn-sm">Read More</button>
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LatestNews;
