import React from 'react'

const Laity = () => {
  return (
    <>
      
    </>
  )
}

export default Laity
