import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layout";
import Home from "./home";
import History from "./pages/AboutUs/history";
import Cathedral from "./pages/AboutUs/Cathedral";
import MassTime from "./pages/AboutUs/MassTime";
import Patron from "./pages/AboutUs/Patron";
import HolyFather from "./pages/AboutUs/HolyFather";
import Monthlyprogramme from "./pages/Bishop/Monthlyprogramme";
import Mannarkkad from "./pages/Parishes/Mannarkkad";
import DiocesanPriests from "./pages/Priests/DiocesanPriests";
import ReligiousSisters from "./pages/Religious/ReligiousSisters";
import TechnicalInstitute from "./pages/Institution/TechnicalInstitute";
import Schools from "./pages/Institution/Schools";
import BishopMessage from "./pages/Bishop/BishopMessage";
import MannarkadDetails from "./pages/Parishes/MannarkadParishDetails/MannarkadDetails";
import Smsss from "./pages/Institution/Smsss";
import Seminary from "./pages/Institution/Seminary";
import Tribunal from "./pages/Institution/Tribunal";
import Contact from "./pages/Contact/Contact";
import Curia from "./pages/Curia/Curia";
import NewsLetter from "./pages/Bishop/NewsLetter";
import Media from "./pages/Ministry/Media";
import Pastroal from "./pages/Ministry/Pastroal";
import Bcc from "./pages/Ministry/Bcc";
import Family from "./pages/Ministry/Family";
import Laity from "./pages/Ministry/Laity";
import Social from "./pages/Ministry/Social";
import Youth from "./pages/Ministry/Youth";
import CalendarEvents from "./pages/Calendar/CalendarEvents";
import UpcomingEvents from "./pages/Events/UpcomingEvents";
import LatestEvents from "./pages/Events/LatestEvents";
import Donate from "./pages/Donation/Donate";
import DailyEventsCalendar from "./pages/Calendar/DailyEventsCalendar";
import AllReligiousDetails from "./pages/Religious/ReligiousPriestDetails/AllReligiousDetails";
import Education from "./pages/Ministry/Education";
import AllPriestDetails from "./pages/Priests/PriestAllDetails/AllPriestDetails";
import Bible from "./pages/Ministry/Pastroal/Bible";
import Liturgy from "./pages/Ministry/Pastroal/Liturgy";
import Cathecetic from "./pages/Ministry/Pastroal/Cathecetic";
import VocationSeminarian from "./pages/Ministry/Pastroal/VocationSeminarian";
import Religious from "./pages/Ministry/Pastroal/Religious";
import Proclamation from "./pages/Ministry/Pastroal/Proclamation";
import Ecumenism from "./pages/Ministry/Pastroal/Ecumenism";
import Theology from "./pages/Ministry/Pastroal/Theology";
import CanonLaw from "./pages/Ministry/Pastroal/CanonLaw";
import PoliticalAffair from "./pages/Ministry/PoliticalAffair";
import Educations from "./pages/Ministry/Education/Educations";
import MediaHeritage from "./pages/Ministry/Education/MediaHeritage";
import FamilyCommision from "./pages/Ministry/Family/FamilyCommision";
import FamilyMalayalam from "./pages/Ministry/Family/FamilyMalayalam";
import ChildCommision from "./pages/Ministry/Family/ChildCommision";
import Justice from "./pages/Ministry/Social/Justice";
import MigraantCommision from "./pages/Ministry/Social/MigraantCommision";
import HealthCommision from "./pages/Ministry/Social/HealthCommision";
import PrisonMinistry from "./pages/Ministry/Social/PrisonMinistry";
import LabourComission from "./pages/Ministry/Social/LabourComission";
import YouthCommision from "./pages/Ministry/Youth/YouthCommision";
import LaityCommision from "./pages/Ministry/Lay/LaityCommision";
import Klca from "./pages/Ministry/Lay/Klca";
import WomenCommision from "./pages/Ministry/Lay/WomenCommision";
import Aided from "./pages/Institution/Schools/Aided";
import Unaided from "./pages/Institution/Schools/Unaided";
import BishopProfile from "./pages/Bishop/BishopProfile";
import Privacy from "./pages/Policy/Privacy";
import Refund from "./pages/Policy/Refund";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          {/* About Us Page Routing */}
          <Route path="history" element={<History />} />
          <Route path="cathedral" element={<Cathedral />} />
          <Route path="mass-timings" element={<MassTime />} />
          <Route path="patron" element={<Patron />} />
          <Route path="holy-father" element={<HolyFather />} />

          {/* Bishop Page Routing */}
          <Route path="monthly-programme" element={<Monthlyprogramme />} />
          <Route path="message" element={<BishopMessage />} />
          <Route path="newsletter" element={<NewsLetter />} />
          <Route path="bishop-profile" element={<BishopProfile />} />
          <Route path="calendar-events" element={<CalendarEvents />} />
          <Route path="daily-events" element={<DailyEventsCalendar />} />

          {/* Curia Page Routing */}
          <Route path="curia" element={<Curia />} />

          {/* Parishes Page Routing */}
          <Route path="vicariate-details" element={<Mannarkkad />} />
          <Route path="palakkad-vicariate" element={<Mannarkkad />} />
          <Route path="sorappara-vicariate" element={<Mannarkkad />} />
          <Route path="palaniarpalayam-vicariate" element={<Mannarkkad />} />

          {/* Parishes Mannarkad Vicariate */}
          <Route path="parish-more-details" element={<MannarkadDetails />} />

          {/* Priests Page Routing */}
          <Route path="diocese-priest" element={<DiocesanPriests />} />
          <Route path="religious-priest" element={<DiocesanPriests />} />

          <Route path="priest-more-details" element={<AllPriestDetails />} />

          {/* Religious Page Routing */}
          <Route path="religious-sister" element={<ReligiousSisters />} />
          <Route path="religious-brother" element={<ReligiousSisters />} />

          {/* Parishes Mannarkad Vicariate */}
          <Route
            path="religious-more-details"
            element={<AllReligiousDetails />}
          />

          {/* Institutions Page Routing */}
          <Route path="technical-institute" element={<TechnicalInstitute />} />
          <Route path="schools" element={<Schools />} />
          <Route path="aided" element={<Aided />} />
          <Route path="unaided" element={<Unaided />} />
          <Route path="smsss" element={<Smsss />} />
          <Route path="seminary" element={<Seminary />} />
          <Route path="tribunal" element={<Tribunal />} />

          {/* Ministries Page Routing */}
          <Route path="pastroal" element={<Pastroal />} />
          <Route path="political" element={<PoliticalAffair />} />
          <Route path="bcc" element={<Bcc />} />
          <Route path="family" element={<Family />} />
          <Route path="laity" element={<Laity />} />
          <Route path="social" element={<Social />} />
          <Route path="youth" element={<Youth />} />
          <Route path="media" element={<Media />} />
          <Route path="educationmini" element={<Education />} />

          {/* Pastroal ministry sub page routing */}
          <Route path="bible" element={<Bible />} />
          <Route path="liturgy" element={<Liturgy />} />
          <Route path="cathechetic" element={<Cathecetic />} />
          <Route path="vocation-seminarian" element={<VocationSeminarian />} />
          <Route path="religious" element={<Religious />} />
          <Route path="proclamation" element={<Proclamation />} />
          <Route path="ecumenism" element={<Ecumenism />} />
          <Route path="theology" element={<Theology />} />
          <Route path="canonlaw" element={<CanonLaw />} />

          {/* Social Ministry Sub Page Routing */}
          <Route path="justice" element={<Justice />} />
          <Route path="migrant-commision" element={<MigraantCommision />} />
          <Route path="health-commision" element={<HealthCommision />} />
          <Route path="prison-ministry" element={<PrisonMinistry />} />
          <Route path="labour-commision" element={<LabourComission />} />

          {/* Education ministry sub page routing */}
          <Route path="educations" element={<Educations />} />
          <Route path="media-heritage" element={<MediaHeritage />} />

          {/* Family Ministry sub page routing */}
          <Route path="family-commision" element={<FamilyCommision />} />
          <Route path="malayalam" element={<FamilyMalayalam />} />
          <Route path="child-commision" element={<ChildCommision />} />

          {/* Youth Ministry Sub Page Routing */}
          <Route path="youth-commision" element={<YouthCommision />} />

          {/* Lay Ministry Sub Page Routing */}
          <Route path="laity-commision" element={<LaityCommision />} />
          <Route path="klca" element={<Klca />} />
          <Route path="women-commision" element={<WomenCommision />} />

          {/* Events Page Routing */}
          <Route path="upcome-events" element={<UpcomingEvents />} />
          <Route path="latest-events" element={<LatestEvents />} />

          <Route path="https://payonline.sultanpetdiocese.org/" element={<Donate />} />
          <Route path="contact-us" element={<Contact />} />

          {/* policy & Refund Page Routing */}
          <Route path="privacy" element={<Privacy />} />
          <Route path="refund" element={<Refund />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
